import React from "react"
import { graphql } from "gatsby"
import BlockContent from "@sanity/block-content-to-react"

import BackButton from "../components/backButton"
import Seo from "../components/seo"
import Localize from "../components/localize"

import { Imprint } from "../svgs/svg"

const ImPrint = ({ data: { imPrint }, pageContext }) => {
  const lan = pageContext.locale

  return (
    <>
      <Seo
        lan={lan}
        title={lan === "en" ? "Imprint" : "Impressum"}
        description={lan === "en" ? "Imprint" : "Impressum"}
        titleTemplate={
          lan === "en"
            ? "Imprint: HaackSchubert - Lawyers, tax consultants, auditors and notaries"
            : "Impressum: HaackSchubert - Rechtsanwälte, Steuerberater, Wirtschaftsprüfer und Notare"
        }
      />
      <div id="content" className="team-button" style={{ paddingTop: 0 }}>
        <div className="header header-height-imprint no--margin-menu header-typ2 about--us-header w-100 height-1050">
          <Imprint />
          <div id="headerText" className="headerText about--us-text about-text">
            <h4>
              {imPrint.title}
              <br />
              {lan === "en" ? "(German)" : ""}
            </h4>
            <BlockContent
              className="imprint"
              blocks={imPrint._rawSubDescription}
            />
          </div>
        </div>
        <div className="simpletext text-align-left">
          <h1>Impressum</h1>
          <BlockContent blocks={imPrint._rawDescription} />
        </div>
        <BackButton
          lan={lan}
          text={lan === "en" ? "Home" : "Startseite"}
          className="back-button"
        />
      </div>
    </>
  )
}

export default Localize(ImPrint)

export const query = graphql`
  query {
    imPrint: sanityImPrint {
      _rawDescription
      _rawSubDescription
      title {
        _type
        de
        en
      }
    }
  }
`
